import type { TemplateData } from '../types';
import styled from '@emotion/styled';
import { AutosizeGrid } from '@imus/base-ui';
import { CustomLinkCard, SocialCard } from './SocialCard';
import { GalleryCard } from './GalleryCard';
import { VideoCard } from './VideoCard';
import { TrackCard } from './TrackCard';

export { getSocialHandlerPrefix } from './SocialCard';

const Grid = styled(AutosizeGrid)`
  & > * {
    aspect-ratio: 1;
    min-height: 170px;
  }
`;

export const BioGrid = ({
  data,
  onRequestPlay,
  audioUrl,
}: {
  data: TemplateData;
  onRequestPlay?: () => void;
  audioUrl?: string;
}) => {
  return (
    <>
      {data.media.filter(
        (m) => ['track', 'image-gallery', 'video-embed'].indexOf(m.type) >= 0
      ).length > 0 ? (
        <Grid gap={8} minWidth={170} maxColumnCount={3}>
          {data.media.map((m, index) => {
            switch (m.type) {
              case 'track': {
                return (
                  <TrackCard
                    key={index}
                    {...m.data}
                    title={m.data.title}
                    release={m.data.release}
                    url={audioUrl || m.data.file?.url}
                    onRequestPlay={onRequestPlay}
                  />
                );
              }
              case 'image-gallery': {
                if (!m.data) return null;
                return <GalleryCard key={index} images={m.data} />;
              }
              case 'video-embed': {
                return <VideoCard key={index} {...m.data} />;
              }
              default:
                return null;
            }
          })}
        </Grid>
      ) : null}
      <AutosizeGrid gap={8} minWidth={189} maxColumnCount={1}>
        {data.media.map((m, index) => {
          switch (m.type) {
            case 'social': {
              return <SocialCard key={index} {...m.data} />;
            }
            case 'custom-link': {
              return <CustomLinkCard key={index} {...m.data} />;
            }
            default:
              return null;
          }
        })}
      </AutosizeGrid>
    </>
  );
};
