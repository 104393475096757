import { useContext, createContext } from 'react';

export const BrandIndicatorContext = createContext<string | boolean>(false);
BrandIndicatorContext.displayName = 'BrandIndicatorContext';

export const BrandIndicator = ({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) => {
  const display = useContext(BrandIndicatorContext);
  if (!display) return null;
  return (
    <svg
      className={className + ' imd-tooltip'}
      style={style}
      data-tooltip-content={display}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FE489D" />
      <path
        d="M14.3334 6L7.33337 12V12.5455L10.2918 12.1997L9.08337 18H9.66671L16.6667 12V11.4545L13.7083 11.8003L14.9167 6H14.3334Z"
        stroke="#FFFBFF"
        strokeWidth="1.5"
      />
    </svg>
  );
};
