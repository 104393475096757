import type { InferGetStaticPropsType } from 'next';
import type { ArtistPagePublishedProps } from '@/shared/fetchStaticData';
import { fetchArtistPageStaticData } from '@/shared/fetchStaticData';
import { ArtistPageLayout } from '@/components/Layout';
import { BioGrid } from '@imus/artist-page-template';
import { useCallback, useState } from 'react';
import ky from 'ky';
import { ComingSoon } from '@/components/index';

const HomePage = ({
  data,
  pageKey,
  fileBaseUrl,
  slug,
  fileRequestSecret,
}: ArtistPagePublishedProps) => {
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined);
  const handleFetchUrl = useCallback(() => {
    const trackCard = data.media.find((m) => m.type === 'track');
    const filename =
      trackCard?.type === 'track' ? trackCard.data.file?.filename : '';

    const fetchUrl = `${process.env.NEXT_PUBLIC_FILEREQUEST_URL}`;

    async function fetchData(url: string) {
      try {
        const response = await ky(url, {
          method: 'POST',
          body: JSON.stringify({
            key: `artist/${pageKey}/files/${filename}`,
            secret: fileRequestSecret,
          }),
          headers: new Headers({ 'content-type': 'application/json' }),
        });
        const data = await response.json<{ url: string }>();
        setAudioUrl(data.url);
      } catch {
        console.error('Ooops no audio');
      }
    }

    fetchData(fetchUrl);
  }, []);

  return (
    <ArtistPageLayout fileBaseUrl={fileBaseUrl} data={data} pageId={slug}>
      <BioGrid data={data} onRequestPlay={handleFetchUrl} audioUrl={audioUrl} />
    </ArtistPageLayout>
  );
};

export default function Page({
  data,
  ...props
}: InferGetStaticPropsType<typeof getStaticProps>) {
  if (data.published) {
    return <HomePage data={data} {...props} />;
  }
  return <ComingSoon data={data} />;
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export const getStaticProps = fetchArtistPageStaticData;
