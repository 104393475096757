import styled from '@emotion/styled';
import { BrandIndicator, SurfaceCard, Window } from '@imus/services-ui';
import { useEffect, useState } from 'react';
import { PlayIcon } from '@imus/base-ui';
import ReactPlayer from 'react-player';
import YoutubeIcon from './images/youtube.svg';
import VimeoIcon from './images/vimeo.svg';

const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie|education)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
const MATCH_URL_SOUNDCLOUD = /(?:soundcloud\.com|snd\.sc)\/[^.]+$/;
const MATCH_URL_VIMEO = /vimeo\.com\/(?!progressive_redirect).+/;
const MATCH_URL_FACEBOOK =
  /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
const MATCH_URL_FACEBOOK_WATCH = /^https?:\/\/fb\.watch\/.+$/;
const MATCH_URL_STREAMABLE = /streamable\.com\/([a-z0-9]+)$/;
const MATCH_URL_WISTIA =
  /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/;
const MATCH_URL_TWITCH_VIDEO = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const MATCH_URL_TWITCH_CHANNEL =
  /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/;
const MATCH_URL_DAILYMOTION =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?(?:[\w.#_-]+)?/;
const MATCH_URL_MIXCLOUD = /mixcloud\.com\/([^/]+\/[^/]+)/;
const MATCH_URL_VIDYARD = /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-_]+)/;
// const MATCH_URL_KALTURA =
//   /^https?:\/\/[a-zA-Z]+\.kaltura.(com|org)\/p\/([0-9]+)\/sp\/([0-9]+)00\/embedIframeJs\/uiconf_id\/([0-9]+)\/partner_id\/([0-9]+)(.*)entry_id.([a-zA-Z0-9-_].*)$/;

export const canPlayUrl = (url: string) =>
  [
    MATCH_URL_YOUTUBE,
    MATCH_URL_VIMEO,
    MATCH_URL_SOUNDCLOUD,
    MATCH_URL_FACEBOOK,
    MATCH_URL_FACEBOOK_WATCH,
    MATCH_URL_STREAMABLE,
    MATCH_URL_WISTIA,
    MATCH_URL_TWITCH_CHANNEL,
    MATCH_URL_TWITCH_VIDEO,
    MATCH_URL_DAILYMOTION,
    MATCH_URL_MIXCLOUD,
    MATCH_URL_VIDYARD,
  ].find((mask) => mask.test(url));

const ProviderText = styled.span`
  color: var(--on-surface, #101010);
  font-feature-settings: 'ss01' on;
  /* Text/XS/Regular */
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 109.091% */
  letter-spacing: 0.33px;
`;

const ProviderIcon = ({
  url,
  ...props
}: {
  url: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  if (MATCH_URL_YOUTUBE.test(url)) return <YoutubeIcon {...props} />;
  if (MATCH_URL_VIMEO.test(url)) return <VimeoIcon {...props} />;
  if (MATCH_URL_SOUNDCLOUD.test(url))
    return <ProviderText {...props}>SoundCloud</ProviderText>;
  if (MATCH_URL_DAILYMOTION.test(url))
    return <ProviderText {...props}>Dailymotion</ProviderText>;
  if (MATCH_URL_FACEBOOK.test(url) || MATCH_URL_FACEBOOK_WATCH.test(url))
    return <ProviderText {...props}>Facebook</ProviderText>;
  if (MATCH_URL_TWITCH_VIDEO.test(url) || MATCH_URL_TWITCH_CHANNEL.test(url))
    return <ProviderText {...props}>Twitch</ProviderText>;
  if (MATCH_URL_MIXCLOUD.test(url))
    return <ProviderText {...props}>Mixcloud</ProviderText>;
  if (MATCH_URL_WISTIA.test(url))
    return <ProviderText {...props}>Wisia</ProviderText>;
  if (MATCH_URL_STREAMABLE.test(url))
    return <ProviderText {...props}>Streamable</ProviderText>;

  return null;
};

const Thumbnail = styled.div`
  overflow: hidden;
  display: flex;
  aspect-ratio: 16/9;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const PlayRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const VideoCardStyled = styled(SurfaceCard)`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  cursor: pointer;
  gap: 16px;
  padding: 16px;
  & > ${Thumbnail}, & > ${PlayRow} {
    pointer-events: none;
  }
  :hover {
    opacity: 0.75;
  }
`;

const PlayIconStyled = styled(PlayIcon)`
  --on-surface: var(--accent);
  height: var(--header-gap, 24px);
  width: var(--header-gap, 24px);
`;
const ProviderIconStyled = styled(ProviderIcon)`
  width: auto;
  height: 12px;
  opacity: 0.5;
  mix-blend-mode: hard-light;
`;

const WindowStyled = styled(Window)`
  margin: 24px;
  width: 100%;
  align-self: center;
  max-width: 1280px;
  aspect-ratio: 16/9;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getThumbnail = (url: string) => {
  const youtubeMatch = MATCH_URL_YOUTUBE.exec(url);
  if (youtubeMatch)
    return `https://i.ytimg.com/vi/${youtubeMatch[1]}/mqdefault.jpg`;

  return true;
};

export const VideoCard = ({ url }: { url: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);
  return (
    <>
      <VideoCardStyled
        onClick={(event) => {
          setIsOpen(true);
          event?.stopPropagation();
          event?.preventDefault();
        }}
      >
        <Thumbnail>
          {hasWindow && (
            <ReactPlayer
              playIcon={<></>}
              width="100%"
              height="100%"
              light={getThumbnail(url)}
              url={url}
            />
          )}
        </Thumbnail>
        <PlayRow>
          <PlayIconStyled />
          <ProviderIconStyled
            url={url}
            style={{
              height: '12px',
            }}
          />
        </PlayRow>

        <BrandIndicator
          style={{
            position: 'absolute',
            zIndex: 1000,
            top: '8px',
            right: '8px',
          }}
        />
      </VideoCardStyled>
      <WindowStyled
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      >
        {hasWindow && (
          <ReactPlayer
            controls={true}
            width="100%"
            height="100%"
            style={{ overflow: 'hidden' }}
            url={url}
          />
        )}
      </WindowStyled>
    </>
  );
};
