import type { FunctionComponent, MouseEvent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { AudioPlayer } from 'react-use-audio-player';
import styled from '@emotion/styled';

interface AudioSeekBarProps {
  playing: AudioPlayer['playing'];
  getPosition: AudioPlayer['getPosition'];
  duration: AudioPlayer['duration'];
  seek: AudioPlayer['seek'];
  className?: string;
}
const Bar = styled.div`
  width: 100%;
  cursor: pointer;
  background: var(--fg-4);
  border-radius: 4px;
  overflow: hidden;
  height: 6px;
  & > div {
    background: var(--fg-1);
    border-radius: 4px 0px 0px 4px;
    height: 100%;
  }
`;

export const AudioSeekBar: FunctionComponent<AudioSeekBarProps> = ({
  playing,
  getPosition,
  duration,
  seek,
}) => {
  const [pos, setPos] = useState(0);
  const frameRef = useRef<number>();

  const seekBarElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animate = () => {
      setPos(getPosition());
      frameRef.current = requestAnimationFrame(animate);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, []);

  const goTo = useCallback(
    (event: MouseEvent) => {
      const { pageX: eventOffsetX } = event;

      if (seekBarElem.current) {
        const elementOffsetX = seekBarElem.current.offsetLeft;
        const elementWidth = seekBarElem.current.clientWidth;
        const percent = (eventOffsetX - elementOffsetX) / elementWidth;
        seek(percent * duration);
      }
    },
    [duration, playing, seek]
  );

  if (duration === Infinity) return null;

  return (
    <Bar ref={seekBarElem} onClick={goTo}>
      <div style={{ width: `${duration ? (pos / duration) * 100 : 0}%` }} />
    </Bar>
  );
};
