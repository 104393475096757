import styled from '@emotion/styled';
import { Text, WithGlobalStyles } from '@imus/services-ui';
import { useTranslation } from 'next-i18next';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--bg-2);
  height: 100vh;
  justify-content: center;
  flex-direction: row;
`;

export function ComingSoon({ data }: { data: { name: string } }) {
  // _props: InferGetStaticPropsType<typeof getStaticProps>
  const { t } = useTranslation();
  return (
    <>
      <WithGlobalStyles />
      <Wrapper>
        <Text.H2>
          {t('artist-page-unpublished', {
            name: data.name,
            defaultValue: 'iMusician Artist Hub of {{name}} is not live yet',
          })}
        </Text.H2>
      </Wrapper>
    </>
  );
}
