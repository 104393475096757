import styled from '@emotion/styled';

const Grid = styled.div<{ minWidth: number; maxWidth: string; gap: string }>`
  display: grid;
  ${({ minWidth, maxWidth }) =>
    minWidth < 300
      ? `

  grid-template-columns: repeat(
    auto-fill,
    minmax(
      max(${minWidth}px, ${maxWidth}),
      1fr
    )
  );

`
      : `
  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        max(${minWidth}px, ${maxWidth}),
        1fr
      )
    );
  }
`}
  grid-gap: ${({ gap }) => gap};
  & > * {
    min-width: 0;
  }
`;

export const AutosizeGrid = ({
  minWidth = 188,
  gap = 16,
  maxColumnCount = 4,
  className,
  style,
  children,
}: {
  children?: React.ReactNode;
  minWidth?: number;
  gap?: number;
  maxColumnCount?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const gapCount = maxColumnCount - 1;
  const totalGapWidth = gapCount * gap;
  const itemMaxWidth = `calc((100% - ${totalGapWidth}px) / ${maxColumnCount})`;
  return (
    <Grid
      style={style}
      className={className}
      maxWidth={itemMaxWidth}
      minWidth={minWidth}
      gap={`${gap}px`}
    >
      {children}
    </Grid>
  );
};
