import styled from '@emotion/styled';
import type { GalleryItem } from '../types';
import Image from 'next/image';
import { Icons, SurfaceCard, Text, Window } from '@imus/services-ui';
import { useEffect, useMemo, useState } from 'react';
import { getFileUrl } from '../utils/getFileUrl';
import { css } from '@emotion/react';
import { useBaseUrl } from '../BaseUrlContext';
import GalleryIcon from './image-gallery.svg';

const ImageCard = styled(SurfaceCard)`
  cursor: pointer;
  position: relative;
  padding: 0;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  :hover {
    opacity: 0.75;
  }
`;

const WindowStyled = styled(Window)`
  box-shadow: none;
  width: auto;
  max-width: 1280px;
  border-radius: none;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  img {
    display: flex;
    flex-grow: 0;
    max-height: 80vh;
    width: auto;
    max-width: 100%;
    height: auto;
  }
`;

const ImageDescription = styled(Text.ContentM)`
  display: block;
  margin-top: 16px;
  color: #fff;
  text-align: center;
`;

const navStyle = css`
  align-items: center;
  justify-content: center;
  position: absolute;
  svg {
    fill: #fff;
  }
  border: none;
  top: 50%;
  cursor: pointer;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  z-index: 204;
  background: rgba(255, 255, 255, 0.1);
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const NextButton = styled.button`
  ${navStyle};
  right: 24px;
  display: ${({ onClick }) => (onClick ? 'flex' : 'none')};
`;

const PrevButton = styled.button`
  ${navStyle};
  left: 24px;
  display: ${({ onClick }) => (onClick ? 'flex' : 'none')};
`;

const GalleryIconStyled = styled(GalleryIcon)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  width: auto;
  height: auto;
  padding: 8px;
  background: #1a1a1a;
  path {
    fill: #fff;
  }
`;

export const GalleryCard = ({ images }: { images: GalleryItem[] }) => {
  const baseUrl = useBaseUrl();
  const [viewedImageIndex, setViewedImageIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const handleNext = useMemo(() => {
    if (viewedImageIndex === images.length - 1) return undefined;
    return () => setViewedImageIndex(viewedImageIndex + 1);
  }, [viewedImageIndex]);

  const handlePrev = useMemo(() => {
    if (viewedImageIndex === 0) return undefined;
    return () => setViewedImageIndex(viewedImageIndex - 1);
  }, [viewedImageIndex]);

  useEffect(() => {
    if (!showGallery) {
      setViewedImageIndex(0);
    }
  }, [showGallery]);
  if (!images.length) return null;
  return (
    <>
      <ImageCard
        onClick={() => {
          setShowGallery(true);
        }}
      >
        <Image
          src={getFileUrl(images[0].file, baseUrl)}
          width={images[0].file?.image?.width}
          height={images[0].file?.image?.height}
          alt={images[0].description || 'gallery'}
        />
        {images.length > 1 && <GalleryIconStyled />}
      </ImageCard>
      <WindowStyled
        controls={
          <>
            <NextButton onClick={handleNext} type="button">
              <Icons.Icon icon="chevron" />
            </NextButton>
            <PrevButton onClick={handlePrev} type="button">
              <Icons.Icon icon="chevronLeft" />
            </PrevButton>
          </>
        }
        isOpen={showGallery}
        close={() => {
          setShowGallery(false);
        }}
      >
        <Container>
          <Image
            src={getFileUrl(images[viewedImageIndex].file, baseUrl)}
            width={images[viewedImageIndex].file?.image?.width}
            height={images[viewedImageIndex].file?.image?.height}
            alt={images[0].description || 'gallery'}
          />
        </Container>
        <ImageDescription>
          {images[viewedImageIndex].description || <>&nbsp;</>}
        </ImageDescription>
      </WindowStyled>
    </>
  );
};
